@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
}

:root {
    --light-bg: rgba(28, 169, 201, 0.15);
    --light-bg2: rgb(230, 230, 230);
    --light-shadow: rgba(28, 169, 201, 0.2);
    --light-grad1: rgba(1, 1, 1, 0.1);
    --light-grad2: rgba(1, 1, 1, 0.05);

    --dark-bg: rgb(8, 20, 33);
    --dark-bg2: rgb(38, 41, 43);
    --dark-shadow: black;
    --dark-grad1: rgba(255, 255, 255, 0.1);
    --dark-grad2: rgba(255, 255, 255, 0.1);;

    --background-colour: var(--dark-bg);
    --secondary-background: var(--dark-bg2);
    --shadow: var(--dark-shadow);
    --grad1: var(--dark-grad1);
    --grad2: var(--dark-grad2);
    
    
    --container-colour: var(--secondary-background);

    --text: white;
    --nav-text: white;
}

@font-face {
    font-family: "montserrat";
    src: url('./assets/Montserrat-Regular.ttf');
}

body {
    font-family: "montserrat",sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: var(--background-colour);
    color: var(--text);
    /*scroll stuff*/
    -ms-overflow-style: none; /** For IE */
    overflow-y: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {display:none;}
@-moz-document url-prefix() {
    html,
    body {
        scrollbar-width: none;
    }
}

body::-webkit-scrollbar {
    display: none; /* remove the scrollbar in chrome */
}

a,
a:hover,
a:visited,
a:active {
    color: inherit;
    text-decoration: none;
}

.light-content {
    --background-colour: var(--light-bg);
    --secondary-background: var(--light-bg2);
    --container-colour: var(--secondary-background);
    --text: black;
    --shadow: var(--light-shadow);
    --grad1: var(--light-grad1);
    --grad2: var(--light-grad2);
}

.dark-content {
    --background-colour: var(--dark-bg);
    --secondary-background: var(--dark-bg2);
    --container-colour: var(--secondary-background);
    --text: white;
    --shadow: var(--dark-shadow);
    --grad1: var(--dark-grad1);
    --grad2: var(--dark-grad2);
}

.main-section {
    min-height: 100vh;
}

.colour-mode-buttons {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-left: 6%;/* add padding to the toggles to make the page buttons centered */
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.footer { /* div for the footer in Footer */
    bottom: 0;
    width: 100%;
    padding: 1%;
    color: var(--text);
}

.about-container {
    /*background-color: var(--container-colour);*/
    background: linear-gradient(to bottom right, var(--grad1), var(--grad2));
    box-shadow: 1px 1px 2px var(--shadow), 0 0 15px var(--shadow), 0 0 5px var(--shadow);
    filter: blur(20);
    border-radius: 5px;
    margin: 1rem;
    margin-left: 10%;
    margin-right: 10%;
    padding: 1rem;
    /* uncomment to make the about and home sections centered
    display: flex;
    flex-direction: column;
    align-items: center;
    */
}

.design-container {
    /*background-color: var(--container-colour);*/
    background: linear-gradient(to bottom right, var(--grad1), var(--grad2));
    box-shadow: 1px 1px 2px var(--shadow), 0 0 15px var(--shadow), 0 0 5px var(--shadow);
    filter: blur(20);
    border-radius: 5px;
    margin: 1rem;
    margin-left: 10%;
    margin-right: 10%;
    padding: 1rem;
    /* uncomment to make the about and home sections centered
    display: flex;
    flex-direction: column;
    align-items: center;
    */
}

.home-container {
    /*background-color: var(--container-colour);*/
    box-shadow: 1px 1px 2px var(--shadow), 0 0 15px var(--shadow), 0 0 5px var(--shadow);
    /* uncomment to make the about and home sections centered
    display: flex;
    flex-direction: column;
    align-items: center;
    */
}

.design-container {
    display: inline-block;
}

.design-container-image {
    border-radius: 5px;
    height: 300px;
}

.about-container-image {
    border-radius: 5px;
    minheight: 300px;
}

.github-icon{
    display: flex;
    justify-content: center;
}

.copyright-text {
    padding-bottom: 1rem;
}

#about-title,
#design-title,
#admin-title{
    margin-top: 1rem;
}

#home-button,
#about-button,
#design-button{
    color: var(--nav-text);
}

#home-page-button{
    color: var(--nav-text);
    background: orange;
}

#home-card{
    background: var(--grad2);
}

#home-card-content{
    color: var(--text);
    font-family: "montserrat",sans-serif;
    font-weight: 400;
    font-style: normal;
}

#about-subheading,
#design-subheading{
    margin-left: 10%;
    margin-right: 10%;
}

#visual { 
    position: absolute; 
    top:0;
    left:0; 
    height:100%; 
    width:100% 
}

.map-container {
    display: flex;
    justify-content: center;
}
