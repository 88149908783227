.admin-input-stack{
    max-width: 60vw;
    min-width: 20vw;
}

.admin-input-stack > .admin-login-label {
    text-align: center;
}

.login-border {
    background: linear-gradient(to bottom right, var(--grad1), var(--grad2));
    box-shadow: 1px 1px 2px var(--shadow), 0 0 15px var(--shadow), 0 0 5px var(--shadow);
    filter: blur(20);
    border-radius: 5px;
    margin: 1rem;
    padding: 2rem;
    height: 19rem;
    translate: 0% 75%;
}

/* dodgy css to get the mui login to recolor */
.MuiTextField-root > label {
    color: var(--text);
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
    color: var(--text);
}

.checkbox-group {
    width: 5%;
    display: flex;
    justify-content: center;
}

.reset-cars {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reset-checkboxes {
    display: flex !important;
    justify-content: center;
}

.reset-cars-button {
    height: 10%;
    min-width: 20%;
    align-self: center;
}

.MuiLoadingButton-root > .MuiLoadingButton-loading {
    color: #ffffff;
    
}

.reset-cars-alert {
    margin-left: 20%;
    margin-right: 20%;
}
/*status continer*/

.car-status-container{
    padding: 1rem;
    background-color: var(--container-colour);
    border-radius: 5px;
    margin: 1rem;
    width: 200px;
    border-width: thin;
}

.restart-server-div {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.regen-map-div {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.restart-server-button {

}   

#username-input,
#password-input {
  background-color: var(--secondary-background);
  color: var(--text);
}